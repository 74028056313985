import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveContactArgs } from '../../generated/types';

export const ARCHIVE_CONTACT = gql`
  mutation ArchiveContact(
    $entityType: EntityType!
    $userUuid: String!
    $boardUuid: String
    $jobUuid: String!
    $contactUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveContact(
      entityType: $entityType
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      contactUuid: $contactUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveContact = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveContact'>, MutationArchiveContactArgs>
) =>
  useMutation<Pick<Mutation, 'archiveContact'>, MutationArchiveContactArgs>(ARCHIVE_CONTACT, {
    update(cache, { data: { archiveContact = {} } = {} }) {
      if (!archiveContact.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Contact',
          uuid: archiveContact.uuid,
        }),
        fields: {
          isDeleted() {
            return archiveContact.isDeleted;
          },
        },
      });
    },
    ...options,
  });
